import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { BootstrapService } from "../service/bootstrap.service";
import { AuthService } from "../service/auth.service";
import { confirmLogin } from "@shared/util/confirm_utils";

export const accessLevel = (privacyLabel: string): CanActivateFn => {
      return async () => {
            const router: Router = inject(Router);
            const WebsiteSetting = inject(BootstrapService).WebsiteSetting;
            const userType: Number = inject(AuthService).LoggedUserValue?.UserType;
            if (WebsiteSetting[privacyLabel] == 'Public') {
                  return true
            }
            if (userType == 1 || userType == 2) {
                  return true
            }
            if (WebsiteSetting[privacyLabel] == 'SiteMembers' && userType == 3) {
                  return true
            }
            const action = await confirmLogin(WebsiteSetting[privacyLabel] || "Member");
            if (action.isConfirmed) {
                  return router.navigate(['/authentication/signin'], { queryParams: { accessLevel: WebsiteSetting[privacyLabel] } })
            }
            return false

      }

};